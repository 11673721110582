@keyframes fadein-up {
    0%{
        opacity: 0;
        transform: translateY(30%);
    }100%{
        opacity: 1;
        transform: translateY(0%);
    }
}

#contact-form-cont{
    width: 48vw;
    height: fit-content;

    background-color: white;

    border: 2px solid var(--B3);

    border-radius: 25px;

    padding: 1vw 3vw;
    margin-left: 2vw;

    font-size: 2.2rem;    
    font-family: 'Arima Koshi Regular', serif;

    animation-name: fadein-up;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
}

#form-intro{
    color: var(--B1);
    font-size: 2.2rem;
    margin-bottom: 30px;
}
#form-intro span{
    font-size: 1.8rem;
    font-style: italic;
}
.form-row{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
}
.form-row .form-field{
    display: flex;
    width: 48%;
}
.form-row .form-field input{
    flex-grow: 1;
    width: 100%;
}
.form-block{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}
.form-block .form-col{
    display: flex;
    flex-direction: column;

    flex-grow: 1;
    width: 100%;
}
.form-col .form-field{
    display: flex;
}
.form-col .form-textarea{
    margin-top: 15px;
    flex-direction: column;
}
.form-textarea textarea{
    resize: none;
    border: 1px solid var(--B4);
    height: 20rem;
}
.form-col .form-field input[type="text"]{
    width: 100%;
    flex-grow: 1;
}
.form-submit{
    cursor: pointer;
    width: 30%;
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-submit:hover circle{
    fill: var(--B2) !important;
}
.form-submit:hover .submit-text{
    color: var(--B2);
}


.form-submit-arrow{
    width: 2.6vw;
    height: auto;
}

.form-field label{
    color: var(--B4);
    margin-right: 10px;
}

.form-field input{
    all: unset;
    height: 2.4rem;
    border: 1px solid var(--B4);
}

.form-field input[type=text],
.form-field input[type=tel],
.form-field input[type=email],
.form-textarea textarea{
    font-family: 'Arima Koshi Regular';
    font-size: 2rem;
    padding: 0.3rem 0.5rem 0rem 0.5rem;   
    color: var(--B2);
}


.submit-text{
    user-select: none;
    display: block;
    height: fit-content;
    width: 100%;
    text-align: center;
    font-family: 'Arima Koshi Black';
    font-weight: 400;
    color: var(--B4);
    border: none;
    margin-top: 10px;
}
.form-incorrect label{
    color: var(--O1) !important;
}

@media screen and (max-width: 1450px) {
    #contact-form-cont{
        width: 56vw;
    }
}

@media screen and (max-width: 800px){
    #contact-form-cont{
        width: 100vw;
        border: none;
        border-radius: 0px;
    
        padding: 1vw 5vw;
        margin-left: 0px;
        margin-bottom: 25px;

        text-align: justify;
    
        font-size: 2.5rem;    
        font-family: 'Arima Koshi Regular', serif;
    }
    #form-intro{
        color: var(--B1);
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
    #form-intro span{
        font-size: 1.8rem;
        font-style: italic;
    }
    .form-row{
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 0px;
    }
    .form-row .form-field{
        width: 100%;
        margin-bottom: 6px;
    }
    .form-block{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
    }
    .form-block .form-col{
        display: flex;
        flex-direction: column;
    
        flex-grow: 1;
        width: 100%;
    }
    .form-col .form-field{
        display: flex;
    }
    .form-submit{
        margin-top: 15px;
        cursor: pointer;
        width: 100%;
        margin-left: 0px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
    .form-submit-arrow{
        width: 13vw;
        height: auto;
    }
    .submit-text{
        user-select: none;
        display: block;
        height: fit-content;
        width: fit-content;
        text-align: right;
        font-family: 'Arima Koshi Black';
        font-weight: 400;
        color: var(--B4);
        border: none;
        margin-top: 0px;
        margin-right: 20px;
    }
}