@keyframes fadein-up {
    0%{
        opacity: 0;
        transform: translateY(30%);
    }100%{
        opacity: 1;
        transform: translateY(0%);
    }
}

#contact-page-aside img{
    height: 23vw;
}

#contact-page-extra{
    width: 35vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#contact-page-pdfs{
    padding: 1vw 1vw;

    width: 70%;
    height: fit-content;
    background-image: linear-gradient(var(--O3GradientTop), var(--O3GradientBot));
    border: 2px solid white;
    border-radius: 25px;

    margin-bottom: 30px;

    animation-name: fadein-up;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
}
#contact-page-pdfs h2{
    font-family: 'Monoton', monospace;
    font-size: 7rem;
    line-height: 7.3rem;
    color: white;
    font-weight: 100;
    margin-bottom: 10px;
}
#contact-page-pdfs p{
    font-family: 'Arima Koshi Regular';
    font-size: 2.3rem;
    color: var(--B1);
}

#contact-page-pdfs a{
    all: unset;

    user-select: none;
    cursor: pointer;
    margin-top: 15px;
    display: block;
    width: fit-content;
    padding: 10px 10px 5px 10px;
    font-family: 'Special Elite', serif;
    font-weight: 200;
    font-size: 2.6rem;
    color: white;
    background-color: var(--B2);
}

#contact-page-pdfs a:hover{
    background-color: var(--B1);
}


#contact-page-linkaband{
    user-select: none;
    cursor: pointer;

    width: 70%;

    padding: 1vw 1vw;

    background-image: linear-gradient(var(--B5GradientTop), var(--B5GradientBot));
    border: 2px solid white;
    border-radius: 25px;

    animation-name: fadein-up;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
}

#contact-page-linkaband a{
    all: unset;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#contact-page-linkaband:hover {
    background-image: none;
    background-color: var(--B5);
}

#linkaband-text{
    width: 70%;
    font-family: 'Arima Koshi Regular', serif;
    font-size: 2.3rem;
    color: var(--B1);
}
#linkaband-logo-contact{
    display: block;
    width: 25%;
    aspect-ratio: 1;
    height: auto;
}
@media screen and (max-width: 1450px) {
    #contact-page-extra{
        width: 40vw;
    }
}

@media screen and (max-width: 800px) {
    #contact-page-extra{
        width: 100vw;
    }
    #contact-page-pdfs{
        padding: 5vw;
        width: 90%;
        margin-bottom: 20px;
    }
    #contact-page-pdfs h2{
        font-family: 'Monoton', monospace;
        font-size: 7rem;
        line-height: 7.3rem;
        color: white;
        font-weight: 100;
        margin-bottom: 10px;
    }
    #contact-page-pdfs p{
        font-family: 'Arima Koshi Regular';
        font-size: 2.6rem;
        color: var(--B1);
    }

    #contact-page-linkaband{
        width: 90%;
        padding: 5vw;
    }
    
    #contact-page-linkaband a{
        all: unset;
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    #linkaband-text{
        width: 70%;
        font-family: 'Arima Koshi Regular', serif;
        font-size: 2.6rem;
        color: var(--B1);
    }
    #linkaband-logo-contact{
        display: block;
        width: 30%;
        aspect-ratio: 1;
        height: auto;
    }

    
}