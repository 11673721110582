@import url(https://fonts.googleapis.com/css2?family=Monoton&family=Special+Elite&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@800&display=swap);
.audio-player{
    position: relative;
    display: flex;
    width: 100%;
    height: 35px;
    justify-content: space-around;
    align-items: center;

    background-color: rgb(255, 255, 255, 0.5);

    font-size: 1.8rem;

    border-radius: 3px;

    overflow: hidden;
}

.audio-player button{
    -webkit-appearance: none;
            appearance: none;
    background-color: transparent;
    border: none;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 35px;
    width: 30px;

    color: var(--B3);

    font-size: 2rem;
}

.audio-player button img{
    display: block;
    height: 50%;
    width: auto;
}

.audio-progress-cont{
    display: flex;
    align-items: center;
    position: relative;
    width: 80%;
    height: 100%;
}

.audio-progress{
    position: relative;
    height: 4px;
    width: 100%;

    background-color: var(--B6);
    flex-grow: 0;
}

.audio-progress-indicator{
    position: absolute;
    top: -4px;
    width: 8px;
    height: 12px;

    background-color: var(--B2);

    border-radius: 3px;

    transition: left 0.8s;
}

.audio-runtime{
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 55px;
    
    font-family: 'Arima Koshi Regular', serif;
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0px 5px 0px 10px;

    margin-top: 3px;
}

.runtime-stopped{
    color: var(--B3);
}
.runtime-playing{
    color: var(--O1);
}
@-webkit-keyframes fleche-apres {
    0%{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }100%{
        -webkit-transform: translateX(30%);
                transform: translateX(30%);
    }
}
@keyframes fleche-apres {
    0%{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }100%{
        -webkit-transform: translateX(30%);
                transform: translateX(30%);
    }
}
@-webkit-keyframes fleche-avant {
    0%{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }100%{
        -webkit-transform: translateX(-30%);
                transform: translateX(-30%);
    }
}
@keyframes fleche-avant {
    0%{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }100%{
        -webkit-transform: translateX(-30%);
                transform: translateX(-30%);
    }
}

.nav-buttons{
    display: flex;
    width: 100%;
    justify-content: center;

    grid-column-gap: 30px;

    -webkit-column-gap: 30px;

            column-gap: 30px;

    margin-top: 20px;
    margin-bottom: 20px;
}
.nav-mobile{
    display: none;
}

.nav-buttons button{
    all: unset;
    cursor: pointer;

    display: block;

    width: 40px;
    height: 40px;
}
.nav-buttons-next:hover,
.nav-buttons-prev:hover{
    -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
}

.nav-buttons-next:hover{
    -webkit-animation-name: fleche-apres;
            animation-name: fleche-apres;  
}
.nav-buttons-prev:hover{
    -webkit-animation-name: fleche-avant;
            animation-name: fleche-avant;
}

.nav-buttons-next .fleche-apres circle,
.nav-buttons-prev .fleche-avant circle{
    transition: fill 0.4s;
}
.nav-buttons-next:hover .fleche-apres circle,
.nav-buttons-prev:hover .fleche-avant circle{
    fill: var(--B3) !important;
}

@media screen and (max-width: 800px) {
    .nav-buttons{
        display: none;
    }

    .nav-mobile{
        all: unset;

        display: block;
        height: auto;
        width: 10%;
    }
    
}

@-webkit-keyframes new-extract {
    0%{
        -webkit-transform: translateY(25%);
                transform: translateY(25%);
        opacity: 0.4;
    }
    100%{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes new-extract {
    0%{
        -webkit-transform: translateY(25%);
                transform: translateY(25%);
        opacity: 0.4;
    }
    100%{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
        opacity: 1;
    }
}

@-webkit-keyframes fadein-up {
    0%{
        opacity: 0;
        -webkit-transform: translateY(15%);
                transform: translateY(15%);
    }100%{
        opacity: 1;
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

@keyframes fadein-up {
    0%{
        opacity: 0;
        -webkit-transform: translateY(15%);
                transform: translateY(15%);
    }100%{
        opacity: 1;
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

.extrait-outer-cont{
   height: -webkit-fit-content;
   height: -moz-fit-content;
   height: fit-content;
   overflow: hidden;
}


.extrait-cont{

    display: flex;
    flex-direction: column;

    padding: 20px 30px;
    width: 23vw;
    height: 27.3vw;
    margin-top: calc(3rem + 15px);

    border-radius: 25px;

    overflow: hidden;

    background-image: linear-gradient(var(--B5GradientTop), var(--B5GradientBot));

    -webkit-animation-name: fadein-up;

            animation-name: fadein-up;
    -webkit-animation-duration: 0.6s;
            animation-duration: 0.6s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}
.extrait-cont h3{
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    font-family: 'Special Elite', serif;
    font-size: 2.5rem;
    font-weight: 400;

    padding: 5px 7px 0px 7px;
}

.extrait-content{
    -webkit-animation-duration: 0.6s;
            animation-duration: 0.6s;
    -webkit-animation-name: new-extract;
            animation-name: new-extract;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
}

#extrait-k7{
    display: flex;
    flex: 1 1;
    justify-content: center;
    min-width: 0;
    min-height: 0;
    max-width: 100%;
    max-height: 55%;

    margin: auto;
}

#extrait-k7 img{
    height: 100%;
    width: auto;
}

.extrait-header{
    width: 100%;
}

.extrait-header h2{
    text-transform: uppercase;

    text-align: center;
    font-family: 'Monoton', monospace;
    font-weight: 100;
    font-size: 6rem;
    letter-spacing: 3px;

    color: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.extrait-artiste{
    background-color: var(--B3);
    color: white;

    margin-top: 20px;
}

.extrait-titre{
    background-color: white;
    color: var(--B1);
}
.extrait-annee{
    background-color: white;
    color: var(--B3);
}

@media screen and (max-width: 1450px) {
    .extrait-header h2{
        font-size: 4.5rem;
    }
}

@media screen and (max-width: 800px){
    .extrait-outer-cont{
        margin-top: 30px;
        position: relative;
        width: 90%;
    }
    .extrait-cont{
        -webkit-animation: none;
                animation: none;
        position: relative;
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin: auto;
        margin-top: 0px;
        padding-bottom: 23px;
    }

    .extrait-header{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
    }

    .extrait-header h2{
        width: 100%;
        text-align: center;
        font-size: 5.5rem;
        letter-spacing: 3px;
    }

    #extrait-k7{
        display: none;
    }

}
@-webkit-keyframes fade-in {
    0%{
        opacity: 0.6;
    }100%{
        opacity: 1;
    }
}

@keyframes fade-in {
    0%{
        opacity: 0.6;
    }100%{
        opacity: 1;
    }
}

@-webkit-keyframes fade-in-mobile {
    0%{
        color: rgb(255, 255, 255, 0.4);
    }100%{
        color: rgb(255, 255, 255, 1);
    }
}

@keyframes fade-in-mobile {
    0%{
        color: rgb(255, 255, 255, 0.4);
    }100%{
        color: rgb(255, 255, 255, 1);
    }
}


@-webkit-keyframes slide-ltr {
    0%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }100%{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}


@keyframes slide-ltr {
    0%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }100%{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

@-webkit-keyframes slide-rtl {
    0%{
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }100%{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

@keyframes slide-rtl {
    0%{
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }100%{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

#home-gallery-cont{
    width: 48vw;
    margin-right: 5vw;
    margin-left: 2vw;
}

.home-item{
    width: 100%;
    overflow-x: hidden;
}

.home-item-title{
    
    background-color: var(--B1);

    z-index: 100;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    padding: 10px 10px 5px 10px;

    -webkit-animation: fade-in 0.6s ease-out 1, slide-ltr 0.6s ease-out 1;

            animation: fade-in 0.6s ease-out 1, slide-ltr 0.6s ease-out 1;
}
.home-item-title h2{
    font-family: 'Special Elite', serif;
    font-size: 3rem;
    font-weight: 300;
    color: white;
}

.home-item-url{
    display: block;
}
.home-item-img{
    width: 100%;
    height: auto;

    z-index: 1000;
    -webkit-animation: fade-in 0.6s ease-out 1, slide-rtl 0.6s ease-out 1;
            animation: fade-in 0.6s ease-out 1, slide-rtl 0.6s ease-out 1;

    border-radius: 0px 25px 25px 25px;
}

@media screen and (max-width: 800px) {
    #home-gallery-cont{
        width: 100vw;
        margin: 0px;
    }
    .home-item-title{
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;

        height: 8.5rem;

        padding-left: 30px;
        padding-right: 30px;
        /*height: fit-content;*/
        -webkit-animation: none;
                animation: none;

        background-color: var(--B3);

        padding-bottom: 8px;
    }
    .home-item-title-cont{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .home-item-title h2{
        display: block;
        width: 70%;
        text-align: center;
        padding: 3px 10px;
        font-size: 3rem;
        margin-top: 3px;
        flex-grow: 1;
        -webkit-animation: fade-in-mobile 0.6s ease-out 1;
                animation: fade-in-mobile 0.6s ease-out 1;
    }
    .home-item-img{
        border-radius: 0px;
    }
}
#mobile-footer{
    display: none;

    margin-top: 20px;
    margin-bottom: 20px;

    padding: 0px 5%;
}
#linkaband-mobile{
    flex-grow: 1;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    height: 70px;
}

#linkaband-mobile img{
    display: block;
    height: 100%;
    width: auto;
}

#social-media-mobile{
    display: flex;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 40px;
    color: var(--B3);
}
#social-media-mobile a{
    display: block;

    height: 40px;
    width: 40px;
}

#social-media-mobile svg{
    width: 100%;
    height: 100%;
}

#social-media-mobile path,
#social-media-mobile circle{
   fill: var(--B4); 
}
#social-media-mobile a:hover path,
#social-media-mobile a:hover circle{
   fill: var(--B1); 
}

@media screen and (max-width: 800px) {
    #mobile-footer{
        display: flex;
        width: 100vw;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;

        align-items: flex-end;

        overflow: hidden;
    }
}
@-webkit-keyframes new-image{
    0%{
        -webkit-transform: translate(150%, 0px) rotate(20deg);
                transform: translate(150%, 0px) rotate(20deg);
    }
    100%{
        -webkit-transform: translate(0%, 0px) rotate(0deg);
                transform: translate(0%, 0px) rotate(0deg);
    }
}

@keyframes new-image{
    0%{
        -webkit-transform: translate(150%, 0px) rotate(20deg);
                transform: translate(150%, 0px) rotate(20deg);
    }
    100%{
        -webkit-transform: translate(0%, 0px) rotate(0deg);
                transform: translate(0%, 0px) rotate(0deg);
    }
}

@-webkit-keyframes new-text-mobile{
    0%{
        -webkit-transform: translate(150%, 0px);
                transform: translate(150%, 0px);
    }
    100%{
        -webkit-transform: translate(0%, 0px);
                transform: translate(0%, 0px);
    }
}

@keyframes new-text-mobile{
    0%{
        -webkit-transform: translate(150%, 0px);
                transform: translate(150%, 0px);
    }
    100%{
        -webkit-transform: translate(0%, 0px);
                transform: translate(0%, 0px);
    }
}


@-webkit-keyframes new-aside-mobile{
    0%{
        -webkit-transform: translate(-150%, 0px);
                transform: translate(-150%, 0px);
    }
    100%{
        -webkit-transform: translate(0%, 0px);
                transform: translate(0%, 0px);
    }
}


@keyframes new-aside-mobile{
    0%{
        -webkit-transform: translate(-150%, 0px);
                transform: translate(-150%, 0px);
    }
    100%{
        -webkit-transform: translate(0%, 0px);
                transform: translate(0%, 0px);
    }
}

@-webkit-keyframes new-text{
    0%{
        -webkit-transform: translate(0%, 50%);
                transform: translate(0%, 50%);
        opacity: 0.4;
    }
    100%{
        -webkit-transform: translate(0%, 0%);
                transform: translate(0%, 0%);
        opacity: 1;
    }
}

@keyframes new-text{
    0%{
        -webkit-transform: translate(0%, 50%);
                transform: translate(0%, 50%);
        opacity: 0.4;
    }
    100%{
        -webkit-transform: translate(0%, 0%);
                transform: translate(0%, 0%);
        opacity: 1;
    }
}

@-webkit-keyframes new-aside{
    0%{
        opacity: 0.3;
    }
    100%{
        opacity: 1;
    }
}

@keyframes new-aside{
    0%{
        opacity: 0.3;
    }
    100%{
        opacity: 1;
    }
}





#groupe-image-side{
    position: relative;
    width: 33vw;
    overflow: hidden;
}

#groupe-image-side img{
    width: 70%;
    -webkit-animation: new-image 0.6s;
            animation: new-image 0.6s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}


#groupe-main{
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 35vw;
    min-height: calc( 100vh - (0.2 * 100vw));
    height: 100%;

    overflow:hidden;

}

#groupe-text-content{
    display: block;

    height: 40rem;

    -webkit-animation: new-text 0.6s;

            animation: new-text 0.6s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    margin-bottom: 20px;
}

#groupe-text-content h2{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    text-transform: uppercase;

    font-size: 7rem;
    color: var(--O1);

    font-family: 'Monoton';
    font-weight: 200;

    letter-spacing: 5px;

    margin: -10px 0px;

}

#groupe-text-content h3{
    display: flex;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;

    padding: 10px 5px 5px 5px;
    margin-bottom: 10px;

    background-color: var(--B2);
    color: white;
    font-size: 2.5rem;
    font-weight: 200;
    font-family: 'Special Elite';
}

#groupe-text-content p{
    display: block;

    font-family: 'Arima Koshi Regular';
    font-size: 2.3rem;
    color: var(--B1);
    font-weight: 300;
}

#groupe-aside{

    color: white;

    font-family: 'Arima Koshi Regular';
    font-size: 1.6rem;

    height: -webkit-fit-content;

    height: -moz-fit-content;

    height: fit-content;
    max-height: 66rem;

    overflow: auto;
    scrollbar-width: thin;

    width: 13vw;
    margin: 0px 2vw;
    padding: 5px 10px 10px 10px;

    background-color: var(--B2);

    -webkit-animation: new-aside 0.6s;

            animation: new-aside 0.6s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

#groupe-aside em{
    font-size: 1.8rem;
    display: block;
    margin-top: 10px;
    font-weight: 600;
}

.greyed-out{
    -webkit-filter: contrast(0%);
            filter: contrast(0%);
}

.selected{
    -webkit-filter: initial;
            filter: initial;
}

.greyed-out:hover{
    -webkit-filter: initial;
            filter: initial;
}
#groupe-selector-outer{
    display: flex;
    justify-content: center;
    width: 100%;
}
.selector-default{
    cursor: alias;
    width: 17%;
}
#groupe-selector{
    width: 76%;

    margin-bottom: 25px;

    margin-right: -10%;
    
    position: relative;
}

#groupe-selector img{
    position: relative;
    transition: -webkit-filter 0.4s;
    transition: filter 0.4s ;
    transition: filter 0.4s, -webkit-filter 0.4s;
}
#groupe-selector img:hover{
    cursor: pointer;
}

#xavier{
    width: 25.5%;
    z-index: 3;
}
#antoine{
    width: 14%;
    z-index: 4;
    margin-left: -5%;
}
#manu{
    width: 16%;
    z-index: 6;
    margin-left: -3%;
}
#seb{
    width: 14%;
    z-index: 5;
    margin-left: -2.2%;
}
#marion{
    width: 13%;
    z-index: 4;
    margin-left: -2.8%;
}
#fred{
    width: 18%;
    z-index: 3;
    margin-left: -3.5%;
}
@media screen and (max-width: 1250px) {
    #groupe-main{
        width: 38vw;
    }
    #groupe-aside{
        width: 16vw;
    }
    #groupe-image-side{
        width: 25vw;
    }
    #groupe-image-side img{
        width: 90%;
    }
}

/*****************CSS MOBILE**********************/
@media screen and (max-width: 800px){
    #groupe-main{
        margin-top: 20px;
        width: 90vw;
        min-height: auto;
    }
    #groupe-selector{
        width: 100%;
    }
    .selector-default{
        display: none;
    }

    #groupe-text-content{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 100%;

        -webkit-animation: new-text-mobile 0.6s;

                animation: new-text-mobile 0.6s;
        -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;

        margin-bottom: 0px;
    }

    #groupe-text-content h2{
        font-size: 6.3rem;
    }

    #groupe-aside-mobile{
        color: white;

        font-family: 'Arima Koshi Regular';
        font-size: 1.8rem;

        height: -webkit-fit-content;

        height: -moz-fit-content;

        height: fit-content;
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
        
        width: 90vw;
        margin: 0px auto;
        margin-top: 20px;
        padding: 5px 10px 10px 10px;

        background-color: var(--B2);

        -webkit-animation: new-aside-mobile 0.6s;

                animation: new-aside-mobile 0.6s;
        -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;

    }

    #groupe-aside-mobile em{
        font-size: 2rem;
        display: block;
        margin-top: 10px;
        font-weight: 600;
    }

}
@-webkit-keyframes fadein-up {
    0%{
        opacity: 0;
        -webkit-transform: translateY(30%);
                transform: translateY(30%);
    }100%{
        opacity: 1;
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

@keyframes fadein-up {
    0%{
        opacity: 0;
        -webkit-transform: translateY(30%);
                transform: translateY(30%);
    }100%{
        opacity: 1;
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

#contact-form-cont{
    width: 48vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    background-color: white;

    border: 2px solid var(--B3);

    border-radius: 25px;

    padding: 1vw 3vw;
    margin-left: 2vw;

    font-size: 2.2rem;    
    font-family: 'Arima Koshi Regular', serif;

    -webkit-animation-name: fadein-up;

            animation-name: fadein-up;
    -webkit-animation-duration: 0.6s;
            animation-duration: 0.6s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

#form-intro{
    color: var(--B1);
    font-size: 2.2rem;
    margin-bottom: 30px;
}
#form-intro span{
    font-size: 1.8rem;
    font-style: italic;
}
.form-row{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
}
.form-row .form-field{
    display: flex;
    width: 48%;
}
.form-row .form-field input{
    flex-grow: 1;
    width: 100%;
}
.form-block{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}
.form-block .form-col{
    display: flex;
    flex-direction: column;

    flex-grow: 1;
    width: 100%;
}
.form-col .form-field{
    display: flex;
}
.form-col .form-textarea{
    margin-top: 15px;
    flex-direction: column;
}
.form-textarea textarea{
    resize: none;
    border: 1px solid var(--B4);
    height: 20rem;
}
.form-col .form-field input[type="text"]{
    width: 100%;
    flex-grow: 1;
}
.form-submit{
    cursor: pointer;
    width: 30%;
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-submit:hover circle{
    fill: var(--B2) !important;
}
.form-submit:hover .submit-text{
    color: var(--B2);
}


.form-submit-arrow{
    width: 2.6vw;
    height: auto;
}

.form-field label{
    color: var(--B4);
    margin-right: 10px;
}

.form-field input{
    all: unset;
    height: 2.4rem;
    border: 1px solid var(--B4);
}

.form-field input[type=text],
.form-field input[type=tel],
.form-field input[type=email],
.form-textarea textarea{
    font-family: 'Arima Koshi Regular';
    font-size: 2rem;
    padding: 0.3rem 0.5rem 0rem 0.5rem;   
    color: var(--B2);
}


.submit-text{
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: block;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    text-align: center;
    font-family: 'Arima Koshi Black';
    font-weight: 400;
    color: var(--B4);
    border: none;
    margin-top: 10px;
}
.form-incorrect label{
    color: var(--O1) !important;
}

@media screen and (max-width: 1450px) {
    #contact-form-cont{
        width: 56vw;
    }
}

@media screen and (max-width: 800px){
    #contact-form-cont{
        width: 100vw;
        border: none;
        border-radius: 0px;
    
        padding: 1vw 5vw;
        margin-left: 0px;
        margin-bottom: 25px;

        text-align: justify;
    
        font-size: 2.5rem;    
        font-family: 'Arima Koshi Regular', serif;
    }
    #form-intro{
        color: var(--B1);
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
    #form-intro span{
        font-size: 1.8rem;
        font-style: italic;
    }
    .form-row{
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 0px;
    }
    .form-row .form-field{
        width: 100%;
        margin-bottom: 6px;
    }
    .form-block{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
    }
    .form-block .form-col{
        display: flex;
        flex-direction: column;
    
        flex-grow: 1;
        width: 100%;
    }
    .form-col .form-field{
        display: flex;
    }
    .form-submit{
        margin-top: 15px;
        cursor: pointer;
        width: 100%;
        margin-left: 0px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
    .form-submit-arrow{
        width: 13vw;
        height: auto;
    }
    .submit-text{
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        display: block;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        text-align: right;
        font-family: 'Arima Koshi Black';
        font-weight: 400;
        color: var(--B4);
        border: none;
        margin-top: 0px;
        margin-right: 20px;
    }
}
@-webkit-keyframes fadein-up {
    0%{
        opacity: 0;
        -webkit-transform: translateY(30%);
                transform: translateY(30%);
    }100%{
        opacity: 1;
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

@keyframes fadein-up {
    0%{
        opacity: 0;
        -webkit-transform: translateY(30%);
                transform: translateY(30%);
    }100%{
        opacity: 1;
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

#contact-page-aside img{
    height: 23vw;
}

#contact-page-extra{
    width: 35vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#contact-page-pdfs{
    padding: 1vw 1vw;

    width: 70%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-image: linear-gradient(var(--O3GradientTop), var(--O3GradientBot));
    border: 2px solid white;
    border-radius: 25px;

    margin-bottom: 30px;

    -webkit-animation-name: fadein-up;

            animation-name: fadein-up;
    -webkit-animation-duration: 0.6s;
            animation-duration: 0.6s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}
#contact-page-pdfs h2{
    font-family: 'Monoton', monospace;
    font-size: 7rem;
    line-height: 7.3rem;
    color: white;
    font-weight: 100;
    margin-bottom: 10px;
}
#contact-page-pdfs p{
    font-family: 'Arima Koshi Regular';
    font-size: 2.3rem;
    color: var(--B1);
}

#contact-page-pdfs a{
    all: unset;

    -webkit-user-select: none;

        -ms-user-select: none;

            user-select: none;
    cursor: pointer;
    margin-top: 15px;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 10px 5px 10px;
    font-family: 'Special Elite', serif;
    font-weight: 200;
    font-size: 2.6rem;
    color: white;
    background-color: var(--B2);
}

#contact-page-pdfs a:hover{
    background-color: var(--B1);
}


#contact-page-linkaband{
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;

    width: 70%;

    padding: 1vw 1vw;

    background-image: linear-gradient(var(--B5GradientTop), var(--B5GradientBot));
    border: 2px solid white;
    border-radius: 25px;

    -webkit-animation-name: fadein-up;

            animation-name: fadein-up;
    -webkit-animation-duration: 0.6s;
            animation-duration: 0.6s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

#contact-page-linkaband a{
    all: unset;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#contact-page-linkaband:hover {
    background-image: none;
    background-color: var(--B5);
}

#linkaband-text{
    width: 70%;
    font-family: 'Arima Koshi Regular', serif;
    font-size: 2.3rem;
    color: var(--B1);
}
#linkaband-logo-contact{
    display: block;
    width: 25%;
    aspect-ratio: 1;
    height: auto;
}
@media screen and (max-width: 1450px) {
    #contact-page-extra{
        width: 40vw;
    }
}

@media screen and (max-width: 800px) {
    #contact-page-extra{
        width: 100vw;
    }
    #contact-page-pdfs{
        padding: 5vw;
        width: 90%;
        margin-bottom: 20px;
    }
    #contact-page-pdfs h2{
        font-family: 'Monoton', monospace;
        font-size: 7rem;
        line-height: 7.3rem;
        color: white;
        font-weight: 100;
        margin-bottom: 10px;
    }
    #contact-page-pdfs p{
        font-family: 'Arima Koshi Regular';
        font-size: 2.6rem;
        color: var(--B1);
    }

    #contact-page-linkaband{
        width: 90%;
        padding: 5vw;
    }
    
    #contact-page-linkaband a{
        all: unset;
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    #linkaband-text{
        width: 70%;
        font-family: 'Arima Koshi Regular', serif;
        font-size: 2.6rem;
        color: var(--B1);
    }
    #linkaband-logo-contact{
        display: block;
        width: 30%;
        aspect-ratio: 1;
        height: auto;
    }

    
}
.extrait-modal-button{
    display: inline-block;

    cursor: pointer;

    border: none;
    box-shadow: none;
    border-image: none;

    font-family: 'Special Elite', cursive;
    font-size: 2rem;
    line-height: 2.5rem;

    color: white;
    background-color: var(--O2);

    margin-left: 8px;
    padding: 3px 3px 0px 3px;
}

.extrait-modal-button:hover{
    background-color: var(--O1);
}


.modal-outer{
    z-index: 4000;

    position: fixed;
    top:0px;
    left: 0px;

    width: 100vw;
    height: 100vh;

    background-color: var(--B1Transp);

    justify-content: center;
    align-items: center;
}

.modal-inner{
    background-color: white;
    border-radius: 25px;

    padding: 15px;


    min-width: 450px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    overflow: hidden;
}

.simple-video-player{
    height: auto;
    width: 45vw;

    aspect-ratio: 560 / 340;

    margin-bottom: -0.4vw;
}

@media screen and (max-width: 800px){
    .modal-inner{
        min-width: 0;
        width: 100vw;
    }
    .simple-video-player{
        width: 100%;
        margin-bottom: -2.5vw;
    }
}
#setlist{
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    width: 33vw;
    min-height: 64vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 60px;
}
#setlist-sort{
    width: 100%;
    display: flex;
    flex-direction: column;
}

#setlist-sort-tab{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.setlist-sort-tab{
    display: flex;
    align-items: center;

    padding: 5px 8px 0px 8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-family: 'Special Elite', cursive;
    font-size: 3rem;
    color: white;

    -webkit-user-select: none;

        -ms-user-select: none;

            user-select: none;
    cursor: pointer;
}

.setlist-sort-annee{
    background-color: var(--O1);
}

.setlist-sort-ABC{
    background-color: var(--B2);
}

.not-focused{
    opacity: 0.4;;
}

.setlist-separator{
    display: block;
    height: 8px;
    width: 100%;
}

.setlist-separator-annee #separator1{
    background-color: var(--O1);
}
.setlist-separator-annee #separator2{
    background-color: var(--O4);
}
.setlist-separator-annee #separator3{
    background-color: var(--O6);
}
.setlist-separator-ABC #separator1{
    background-color: var(--B2);
}
.setlist-separator-ABC #separator2{
    background-color: var(--B3);
}
.setlist-separator-ABC #separator3{
    background-color: var(--B4);
}

#setlist-nav{
    display: flex;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: column;
    flex-wrap: wrap;

    font-family: 'Monoton', cursive;
    font-size: 4.6rem;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.setlist-nav-top{
    display: flex;
    width: 100%;
    padding: 0px 12%;

    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.nav-annee{
    color: var(--O4);
}

.nav-ABC{
    color: var(--B4);
}


.setlist-nav-button{
    background-color: white;
    box-shadow: none;
    border: none;
    display: block;
    width: 50px;
    height: 50px;

    cursor: pointer;
}

.nav-annee .setlist-nav-button:hover svg circle{
    fill: var(--O1) !important;
}

.nav-ABC .setlist-nav-button:hover svg circle{
    fill: var(--B2) !important;
}

.setlist-nav-separator{
    display: block;
    width: 100%;
    height: 3px;
}

.nav-annee .setlist-nav-separator{
    background-color: var(--O6);
}

.nav-ABC .setlist-nav-separator{
    background-color: var(--B4);
}

.setlist-group{
    display: inline-block;
    -webkit-column-span: none;
            column-span: none;
    -webkit-column-count: 1;
            column-count: 1;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
    width: 100%;
    margin-top: 10px;
    font-family: 'Arima Koshi Regular', serif;
    color: var(--B1);
    font-size: 2rem;
}

.setlist-artiste-name{
    display: inline-block;
    width: 100%;
    font-family: 'Arima Koshi Black', serif;
    font-size: 2.4rem;
}

.setlist-track{
    display: inline-block;
    width: 100%;

}

#setlist-cont{
    display: block;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: clip;
    -ms-overflow-style: thin;  /* IE and Edge */
    scrollbar-width: thin;  /* Firefox */
}
#setlist-window{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
    display: block;
    -webkit-columns: 2;
            columns: 2;
    width: 100%;
}




#setlist-cont::-webkit-scrollbar{
    display: none;
}

@media screen and (max-width: 1250px) {
    #setlist{
        width: 38vw;
    }
}

@media screen and (max-width: 800px) {
    .setlist-sort-tab{
        height: 9rem;
        font-size: 3rem;
        width: 50%;
        justify-content: center;
    }

    .setlist-nav-top{
        padding:3px 2%;
    }
    
    #setlist{
        display: flex;
        flex-direction: column;
        font-size: 2rem;
        width: 100%;
        min-height: auto;
        margin-bottom: 00px;
    }

    #setlist-cont{
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
        padding:0px 4%;
    
        overflow: auto;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    #setlist-window{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        page-break-inside: avoid;
        -webkit-column-break-inside: avoid;
                break-inside: avoid;
        display: block;
        -webkit-columns: 1;
                columns: 1;
        width: 100%;
    }

    .setlist-group{
        font-size: 2.4rem;
    }
    
    .setlist-artiste-name{
        font-size: 2.8rem;
    }
}
#setlist-vinyl{
  position: relative;

  display: block;
  width: 17vw;  
  height: calc(17vw * 1.26);

  margin-right: 13vw;

  z-index: 3;
}

#setlist-vinyl-cover{
    display: block;

    position: relative;

    width: 100%;
    height: 100%;

    background-image: url("https://media.vancouver-live.fr/site/setlist/Setlist_pochette.png");

    background-size: auto 100%;

    background-repeat: no-repeat;

    z-index: 10;
}



#setlist-vinyl-disc{
    display: block;

    position: absolute;

    right: -7.5vw;
    top: 1%;
    width: calc(95% * 1.26);
    height: 95%;

    background-image: url("https://media.vancouver-live.fr/site/setlist/Setlist_vinyl.png");

    background-size: auto 100%;

    background-repeat: no-repeat;

    z-index: 5;

}

@-webkit-keyframes in-out{
    0% {-webkit-transform: translateX(0%);transform: translateX(0%);}
    50% {-webkit-transform: translateX(-17%);transform: translateX(-17%);}
    100% {-webkit-transform: translateX(0%);transform: translateX(0%);}
}

@keyframes in-out{
    0% {-webkit-transform: translateX(0%);transform: translateX(0%);}
    50% {-webkit-transform: translateX(-17%);transform: translateX(-17%);}
    100% {-webkit-transform: translateX(0%);transform: translateX(0%);}
}

@-webkit-keyframes spin{
    from {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}

@keyframes spin{
    from {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}

.play-in-out{
    -webkit-animation-name: in-out;
            animation-name: in-out;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

.play-spin{
    -webkit-animation-name: spin;
            animation-name: spin;
    -webkit-animation-duration: 0.6s;
            animation-duration: 0.6s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

@media screen and (max-width: 1250px) {
    #setlist-vinyl{
        width: 14vw;  
        height: calc(14vw * 1.26);
      
        margin-right: 10vw;
      }
      #setlist-vinyl-disc{  
          right: -4.5vw;
      }
}

@media screen and (max-width: 800px) {
    #setlist-vinyl{
        display: none;
    }  
}

@-webkit-keyframes fadein-up {
    0%{
        opacity: 0;
        -webkit-transform: translateY(15%);
                transform: translateY(15%);
    }100%{
        opacity: 1;
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}


@keyframes fadein-up {
    0%{
        opacity: 0;
        -webkit-transform: translateY(15%);
                transform: translateY(15%);
    }100%{
        opacity: 1;
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}


#zoom{
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 23vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    background-color: var(--O6);
    border: solid 2px var(--O4);
    border-radius: 30px;

    padding: 25px;

    margin-left: 7vw;
    

    overflow: hidden;

    -webkit-animation-name: fadein-up;

            animation-name: fadein-up;
    -webkit-animation-duration: 0.6s;
            animation-duration: 0.6s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

#zoom-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    margin-bottom: 12px;
}

#zoom-logo{
    display: block;
    width: 13%;
    align-self: flex-start;
}

#zoom-cover{
    width: 82%;
    height: auto;
}

#zoom-body h3{
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Special Elite';
    font-size: 2.6rem;

    font-weight: 400;

    padding: 4px 12px 0px 12px;
}

#zoom-texte{
    margin-top: 12px;
    font-family: 'Special Elite';
    font-size: 1.9rem;
    line-height: 2.4rem;
    color: var(--B3);
}

#zoom-artiste{
    background-color: var(--B2);
    color: white;
}

#zoom-titre{
    background-color: white;
    color: var(--B1);
}

#zoom-annee{
    color: var(--B4);
}
@media screen and (max-width: 1250px) {
    #zoom{
        width: 26vw;
    }
}

@media screen and (max-width: 800px) {
    #zoom{
        margin: 0px;
        margin-top: 30px;
        margin-bottom: 20px;
        width: 92%;

        -webkit-animation: none;

                animation: none;
    }

    #zoom-body h3{
        font-size: 3rem;
    }

    #zoom-texte{
        font-size: 2.3rem;
    }
}
.photo-gallery-mobile{
    display: none;
}

.photo-gallery-desktop{
    all: unset;

    cursor: pointer;
    position: absolute;
    height: 5vw;
    width: auto;


    right: 0px;
    top: calc((48vw / 1.64) + (3rem + 15px) - 5vw);
    margin-right: 25px;
}

.photo-gallery-desktop:hover #photo-gallery-button,
.gallery-opened #photo-gallery-button{
    opacity: 0;
}
.photo-gallery-desktop:hover #gallery-photo-button-hover,
.gallery-opened #gallery-photo-button-hover{
    opacity: 1;
}

#photo-gallery-button{
    height: 100%;
    width: auto;
    margin-right: 0px;
    opacity: 1;
    font-family: 'Special Elite';
}
#gallery-photo-button-hover{
    position: absolute;
    opacity: 0;
    height: 100%;
    width: auto;
    margin-right:0px;
    top: 0px;
    left: 0px;
}

@media screen and (max-width: 1450px) {
    .photo-gallery-desktop{
        height: 7vw;
        top: calc((48vw / 1.64) + (3rem + 15px) - 7vw);
    }
}

@media screen and (max-width: 800px){
    .photo-gallery-mobile{
        all: unset;

        width: 100%;
        display: block;

        width: -webkit-fit-content;

        width: -moz-fit-content;

        width: fit-content;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;

        margin: 20px auto -10px auto;
    }

    .photo-gallery-mobile img{
        width: 50vw;
        height: auto;
    }
}
@-webkit-keyframes fade-in {
    0%{
        opacity: 0.6;
    }100%{
        opacity: 1;
    }
}

@keyframes fade-in {
    0%{
        opacity: 0.6;
    }100%{
        opacity: 1;
    }
}

@-webkit-keyframes fade-in-mobile {
    0%{
        color: rgb(255, 255, 255, 0.4);
    }100%{
        color: rgb(255, 255, 255, 1);
    }
}

@keyframes fade-in-mobile {
    0%{
        color: rgb(255, 255, 255, 0.4);
    }100%{
        color: rgb(255, 255, 255, 1);
    }
}


@-webkit-keyframes slide-ltr {
    0%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }100%{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}


@keyframes slide-ltr {
    0%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }100%{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

@-webkit-keyframes slide-rtl {
    0%{
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }100%{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

@keyframes slide-rtl {
    0%{
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }100%{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

#video-gallery-cont{
    width: 48vw;
    margin-right: 5vw;
    margin-left: 2vw;
}

.video-item{
    width: 100%;
    overflow-x: hidden;
}

.video-item-title{
    
    background-color: var(--B1);

    z-index: 100;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    padding: 10px 10px 5px 10px;

    -webkit-animation: fade-in 0.6s ease-out 1, slide-ltr 0.6s ease-out 1;

            animation: fade-in 0.6s ease-out 1, slide-ltr 0.6s ease-out 1;
}
.video-item-title h2{
    font-family: 'Special Elite', serif;
    font-size: 3rem;
    font-weight: 300;
    color: white;
}
.video-gallery-player-cont{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 1000;
    -webkit-animation: fade-in 0.6s ease-out 1, slide-rtl 0.6s ease-out 1;
            animation: fade-in 0.6s ease-out 1, slide-rtl 0.6s ease-out 1;

    border-radius: 0px 25px 25px 25px;
    overflow: hidden;
    background-color: #282828;
}
.video-gallery-player{
    width: 100%;
    height: auto;
    aspect-ratio: 560 / 340;

    margin-bottom: -0.15vw;
}

.extrait-gallery-custom{
    height: calc(48vw / 1.64);
}

@media screen and (max-width: 800px) {
    #video-gallery-cont{
        width: 100vw;
        margin: 0px;
    }
    .video-item-title{
        display: block;
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        -webkit-animation: none;
                animation: none;

        background-color: var(--B3);

        padding-bottom: 8px;
    }
    .video-item-title-cont{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .video-item-title h2{
        text-align: center;
        font-size: 2.5rem;
        margin-top: 3px;
        flex-grow: 1;
        -webkit-animation: fade-in-mobile 0.6s ease-out 1;
                animation: fade-in-mobile 0.6s ease-out 1;
    }
    .video-gallery-player-cont{
        border-radius: 0px;
    }

    .video-gallery-player{
        width: 100%;
    }
    .extrait-gallery-custom{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
}
#medias-page-aside img{
    height: calc(0.23 * 100vw);
}

@-webkit-keyframes modal-fleche-apres {
    0%{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }100%{
        -webkit-transform: translateX(30%);
                transform: translateX(30%);
    }
}
@keyframes modal-fleche-apres {
    0%{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }100%{
        -webkit-transform: translateX(30%);
                transform: translateX(30%);
    }
}
@-webkit-keyframes modal-fleche-avant {
    0%{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }100%{
        -webkit-transform: translateX(-30%);
                transform: translateX(-30%);
    }
}
@keyframes modal-fleche-avant {
    0%{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }100%{
        -webkit-transform: translateX(-30%);
                transform: translateX(-30%);
    }
}

@-webkit-keyframes fade-in {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes fade-in {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@-webkit-keyframes rtl {
    0%{
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
    100%{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
}

@keyframes rtl {
    0%{
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
    100%{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
}

@-webkit-keyframes ltr {
    0%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
    100%{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
}

@keyframes ltr {
    0%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
    100%{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
}

@-webkit-keyframes slide-down {
    0%{
        -webkit-transform:  translateY(-100%);
                transform:  translateY(-100%);
    }
    65%{
        -webkit-transform: translateY(5%);
                transform: translateY(5%);
    }
    75%{
        -webkit-transform: translateY(-5%);
                transform: translateY(-5%);
    }
    100%{
        -webkit-transform:  translateY(0%);
                transform:  translateY(0%);
    }
}

@keyframes slide-down {
    0%{
        -webkit-transform:  translateY(-100%);
                transform:  translateY(-100%);
    }
    65%{
        -webkit-transform: translateY(5%);
                transform: translateY(5%);
    }
    75%{
        -webkit-transform: translateY(-5%);
                transform: translateY(-5%);
    }
    100%{
        -webkit-transform:  translateY(0%);
                transform:  translateY(0%);
    }
}


@-webkit-keyframes slide-up {
    0%{
        -webkit-transform:  translateY(100%);
                transform:  translateY(100%);
    }
    100%{
        -webkit-transform:  translateY(0%);
                transform:  translateY(0%);
    }
}


@keyframes slide-up {
    0%{
        -webkit-transform:  translateY(100%);
                transform:  translateY(100%);
    }
    100%{
        -webkit-transform:  translateY(0%);
                transform:  translateY(0%);
    }
}

.gallery-mobile-legende{
    display: none;
}
.mobile-gallery-nav{
    display: none;
}

.gallery-modal-outer{
    width: 100vw;
    height: 100vh;

    position: fixed;

    top:0px;
    left: 0px;

    background-color: var(--B1Transp);

    justify-content: center;
    align-items: center;
}

.gallery-modal-cont{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.gallery-modal-cont img{
    display: block;
    border: 8px solid white;
}
.modal-img-outer-cont{
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.modal-img-inner-cont{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    height: 75vh;

    margin: 0px 3vw;
}

.gallery-img-landscape{
    width: 100%;
    height: auto;
}

.gallery-img-portrait{
    height: 100%;
    width: auto;
}

.gallery-legend{
    font-family: 'Special Elite';
    color: white;
    font-size: 2.2rem;
    font-weight: 100;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 40px auto 15px auto;
}

.gallery-legend,
.gallery-modal-cont img{
    -webkit-animation-name: fade-in;
            animation-name: fade-in;
    -webkit-animation-duration: 0.6s;
            animation-duration: 0.6s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

.gallery-modal-cont img{
    -webkit-animation-name: slide-down;
            animation-name: slide-down;
    -webkit-animation-duration: 0.6s;
            animation-duration: 0.6s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
}

.gallery-legend{
    -webkit-animation-name: slide-up;
            animation-name: slide-up;
    -webkit-animation-duration: 0.6s;
            animation-duration: 0.6s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

.modal-nav-prev,
.modal-nav-next{
    all: unset;
    
    cursor: pointer;
    display: block;

    width: 60px;
    height: 60px;
}

.modal-nav-prev:hover,
.modal-nav-next:hover{
    -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
}

.modal-fleche-apres circle,
.modal-fleche-avant circle{
    transition: fill 0.4s;
}
.modal-nav-next:hover .modal-fleche-apres circle,
.modal-nav-prev:hover .modal-fleche-avant circle{
    fill: var(--B3) !important;
}

.modal-nav-next:hover{
    -webkit-animation-name: fleche-apres;
            animation-name: fleche-apres;  
}
.modal-nav-prev:hover{
    -webkit-animation-name: fleche-avant;
            animation-name: fleche-avant;
}

@media screen and (max-width: 800px) {
    .gallery-modal-cont{
        height: 100%;
        width: 100%;
    }

    .modal-img-outer-cont{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        height: 100vh;
    }

    .modal-img-inner-cont{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 93vh;
    
        margin: 0px 0px;
    }
    
    .gallery-modal-cont img{
        border: 4px solid white;
    }

    .ltr-mobile{
        -webkit-animation-name: ltr !important;
                animation-name: ltr !important;
        -webkit-animation-duration: 0.4s !important;
                animation-duration: 0.4s !important;
        -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
        -webkit-animation-timing-function: ease-out !important;
                animation-timing-function: ease-out !important;
    }

    .rtl-mobile{
        -webkit-animation-name: rtl !important;
                animation-name: rtl !important;
        -webkit-animation-duration: 0.4s !important;
                animation-duration: 0.4s !important;
        -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
        -webkit-animation-timing-function: ease-out !important;
                animation-timing-function: ease-out !important;
    }

    .gallery-mobile-legende{
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 100%;

        height: 7vh;

        background-color: var(--B3);

        overflow: hidden;
    }

    .gallery-legend{
        padding: 15px 20px 10px 20px;
        margin: 0px;
        text-align: center;

        flex-grow: 1;

        width: auto;

        line-height: 3rem;

        -webkit-animation-duration: 0.4s;

                animation-duration: 0.4s;
    }

    .gallery-mobile-close{
        all: unset;

        display: flex;
        justify-content: center;
        align-items: center;

        height: 100%;
        aspect-ratio: 1 / 1;
        width: auto;
    }

    .gallery-mobile-close img{
        border: none;
        display: block;

        height: 70%;
        width: auto;
    }

    .mobile-gallery-nav{
        display: block;
        position: absolute;
        z-index: 4000;
        width: 50vw;
        height: 93vh;

        top: 0px;
    }

    .mobile-gallery-nav-next{
        left: 50vw;
    }
    .mobile-gallery-nav-prev{
        left: 0px;
    }

    .gallery-img-portrait{
        width: 100%;
        height: auto;
    }
     
}

header{
    position: relative;
}

#nav-desktop{
    position: relative;
    left: 23%;
    top:0px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    margin-bottom: calc(-0.015 * 100vw);

    display: flex;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}
#social-media-desktop{
    display: flex;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;

    position: absolute;
    top: 0px;
    right: 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 41px;
    margin-bottom: calc(-0.015 * 100vw);
}
#social-media-desktop path,
#social-media-desktop circle{
   fill: var(--B4); 
}
#social-media-desktop a:hover path,
#social-media-desktop a:hover circle{
   fill: var(--B1); 
}


#nav-mobile{
    display: none;
}

#banner-desktop{
    width: 100%;
    height: calc(0.13 * 100vw);
    background-image: url('https://media.vancouver-live.fr/site/charte/Header.jpg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

nav a{
    display: flex;
    align-items: flex-end;

    box-sizing: content-box;

    text-decoration: none;
    color: var(--B1);

    text-transform: uppercase;

    font-family: 'Special Elite', cursive;
    font-size: 2rem;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    height: 35px;

    border-top: 6px solid rgb(0, 0, 0, 0);
}

nav a:hover{
    color: var(--O1);
}

a.nav-focus{
    border-top: 6px solid var(--O1);
    color: var(--O1);
}

#mobile-menu-nav a{
    border-top: none;
}

#mobile-menu-nav a.nav-focus{
    border-top: none !important;
}


/***********NAV MOBILE*************/
@media screen and (max-width: 800px) {
    #nav-desktop{
        display: none;
    }
    #banner-desktop{
        height: calc(0.34 * 100vw);
        background-image: url('https://media.vancouver-live.fr/site/mobile/Header_mobile.jpg');
    }

    #nav-mobile{
        position: relative;
        display: block;
        width: 100%;
        max-height: 100px;
        height: calc(0.2 * 100vw);

        overflow: hidden;
    }

    #nav-mobile h2{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-height: 100px;

        height: calc(0.2 * 100vw);
        font-family: 'Monoton', cursive;
        font-size: 40px;
        color: var(--B3);
        text-transform: uppercase;
        
    }

    #mobile-menu{
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        background-color: white;
        width: 100%;
        height: 100%;
        min-width: 0;

        -webkit-transform: translateX(90%);

                transform: translateX(90%);
    }

    #mobile-menu-open{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 10%;

        color: white;
        font-family: 'Barlow Condensed', cursive;

        -webkit-user-select: none;

            -ms-user-select: none;

                user-select: none;
        cursor: pointer;
        
    }

    .mobile-default{
        background-color: var(--B3);
    }
    .mobile-open{
        background-color: var(--O1);
        width: 5% !important;
        min-width: auto !important;
    }

    #mobile-menu-open h5{
        font-size: 22px;
        letter-spacing: 2px;

        pointer-events: none;
    }

    #mobile-menu-nav{
        background-color: var(--O7);

        

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;

        /*min-width: 80%;*/
        flex: 1 1;

        height: 100%;

        padding: 5px 0px 5px 30px;

        grid-column-gap: 10px;

        -webkit-column-gap: 10px;

                column-gap: 10px;
        grid-row-gap: 0px;
        row-gap: 0px;
    }
    #mobile-menu-nav a{
        display: flex;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        max-height: 40px;
        height: calc((0.1 * 100vw) - 10px);
        font-size: 20px;
    }

    #mobile-menu-close{
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
        width: 12%;
        height: 100%;
    }
    #mobile-menu-close img{
        height: 100%;
        width: auto;
    }
}

@font-face {
    font-family: 'Arima Koshi Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Arima Koshi Regular'), url(/static/media/ArimaKoshi-Regular.bfa9bb8e.woff) format('woff');
}

@font-face {
    font-family: 'Arima Koshi Black';
    font-style: normal;
    font-weight: normal;
    src: local('Arima Koshi Black'), url(/static/media/ArimaKoshi-ExtraBold.d29d2805.woff) format('woff');
}

:root{
    --B1: #384d61;
    --B2: #476e94;
    --B3: #5c80a3;
    --B4: #85a6c7;
    --B5: #80cfe0;
    --B6: #85a7c7;

    --O1: #e04714;
    --O2: #eb694d;
    --O3: #ffb58a;
    --O4: #f5b0a1;
    --O5: #b56961;
    --O6: #ffebb5;
    --O7: #f5f2e4;

    --B5GradientTop: rgb(128, 207, 224);
    --B5GradientBot: rgb(128, 207, 224, 0.6);

    --O3GradientTop: rgb(255, 181, 138);
    --O3GradientBot: rgb(255, 181, 138, 0.6);

    --B1Transp: rgb(56, 77, 97, 0.9);

}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
#root{
    width: 100%;
    min-height: 100vh;
}

body{
    position: relative;
    min-height: 100vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 0;

    background-image: url('https://media.vancouver-live.fr/site/charte/Motif_gauche.jpg'), url('https://media.vancouver-live.fr/site/charte/Motif_droit.jpg');
    background-position: left bottom, right bottom;
    background-repeat: no-repeat, no-repeat;
    background-size: 33%, 33%;

    overflow: auto;
}

main{
    position: relative;
    padding-top: 2%;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    min-height: calc(100vh - (0.134 * 100vw));
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.mobile-only-block, 
.mobile-only-flex{
    display: none;
}

/* TEXTE VERTICAL */
.rotated {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.titre-page-aside{
    position: relative;
    display: flex;
    width: 15vw;
    justify-content: flex-end;
    align-items: flex-start;
}

.titre-page-aside img{
    /*width: 2.5vw;*/
    height: calc(0.28 * 100vw);
    width: auto !important;
    margin-right: 25px;
}

@media (-webkit-device-pixel-ratio: 2){
    html{
        font-size: 9px;
    }
}

@media (-webkit-device-pixel-ratio: 1.5){
    html{
        font-size: 8px;
    }
}

/***********    FONT POUR ECRAN AVEC ZOOM DE 125%   ***************/
@media (-webkit-device-pixel-ratio: 1.25){
    html{
        font-size: 8px;
    }
}

/*****        FONT POUR ECRAN SANS ZOOM       *******/
@media (-webkit-device-pixel-ratio: 1){
    html{
        font-size: 9px;
    }
}
@media screen and (max-width: 1450px) {
    html{
        font-size: 8px;
    }
}

/*************** STYLE MOBILE ***************/
@media screen and (max-width: 800px){
    html{
        touch-action: manipulation;
        font-size: 7px;
    }

    main{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        min-height: 0;

        flex-direction: column;
        align-items: center;
        padding-top: 0px;
    }

    body{
        height: auto;

        min-height: auto;

        /*background-image: none;*/
    }

    .mobile-only-block{
        display: block;
    }

    .mobile-only-flex{
        display: flex;
    }

    .desktop-only{
        display: none !important;
    }

}

