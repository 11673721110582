@keyframes modal-fleche-apres {
    0%{
        transform: translateX(0%);
    }100%{
        transform: translateX(30%);
    }
}
@keyframes modal-fleche-avant {
    0%{
        transform: translateX(0%);
    }100%{
        transform: translateX(-30%);
    }
}

@keyframes fade-in {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes rtl {
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(0%);
    }
}

@keyframes ltr {
    0%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(0%);
    }
}

@keyframes slide-down {
    0%{
        transform:  translateY(-100%);
    }
    65%{
        transform: translateY(5%);
    }
    75%{
        transform: translateY(-5%);
    }
    100%{
        transform:  translateY(0%);
    }
}


@keyframes slide-up {
    0%{
        transform:  translateY(100%);
    }
    100%{
        transform:  translateY(0%);
    }
}

.gallery-mobile-legende{
    display: none;
}
.mobile-gallery-nav{
    display: none;
}

.gallery-modal-outer{
    width: 100vw;
    height: 100vh;

    position: fixed;

    top:0px;
    left: 0px;

    background-color: var(--B1Transp);

    justify-content: center;
    align-items: center;
}

.gallery-modal-cont{
    height: fit-content;
    width: fit-content;
}

.gallery-modal-cont img{
    display: block;
    border: 8px solid white;
}
.modal-img-outer-cont{
    display: flex;
    align-items: center;
    width: fit-content;
    height: fit-content;
}

.modal-img-inner-cont{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    height: 75vh;

    margin: 0px 3vw;
}

.gallery-img-landscape{
    width: 100%;
    height: auto;
}

.gallery-img-portrait{
    height: 100%;
    width: auto;
}

.gallery-legend{
    font-family: 'Special Elite';
    color: white;
    font-size: 2.2rem;
    font-weight: 100;
    display: block;
    width: fit-content;
    margin: 40px auto 15px auto;
}

.gallery-legend,
.gallery-modal-cont img{
    animation-name: fade-in;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
}

.gallery-modal-cont img{
    animation-name: slide-down;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
}

.gallery-legend{
    animation-name: slide-up;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
}

.modal-nav-prev,
.modal-nav-next{
    all: unset;
    
    cursor: pointer;
    display: block;

    width: 60px;
    height: 60px;
}

.modal-nav-prev:hover,
.modal-nav-next:hover{
    animation-duration: 0.4s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-out;
}

.modal-fleche-apres circle,
.modal-fleche-avant circle{
    transition: fill 0.4s;
}
.modal-nav-next:hover .modal-fleche-apres circle,
.modal-nav-prev:hover .modal-fleche-avant circle{
    fill: var(--B3) !important;
}

.modal-nav-next:hover{
    animation-name: fleche-apres;  
}
.modal-nav-prev:hover{
    animation-name: fleche-avant;
}

@media screen and (max-width: 800px) {
    .gallery-modal-cont{
        height: 100%;
        width: 100%;
    }

    .modal-img-outer-cont{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        height: 100vh;
    }

    .modal-img-inner-cont{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 93vh;
    
        margin: 0px 0px;
    }
    
    .gallery-modal-cont img{
        border: 4px solid white;
    }

    .ltr-mobile{
        animation-name: ltr !important;
        animation-duration: 0.4s !important;
        animation-iteration-count: 1;
        animation-timing-function: ease-out !important;
    }

    .rtl-mobile{
        animation-name: rtl !important;
        animation-duration: 0.4s !important;
        animation-iteration-count: 1;
        animation-timing-function: ease-out !important;
    }

    .gallery-mobile-legende{
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 100%;

        height: 7vh;

        background-color: var(--B3);

        overflow: hidden;
    }

    .gallery-legend{
        padding: 15px 20px 10px 20px;
        margin: 0px;
        text-align: center;

        flex-grow: 1;

        width: auto;

        line-height: 3rem;

        animation-duration: 0.4s;
    }

    .gallery-mobile-close{
        all: unset;

        display: flex;
        justify-content: center;
        align-items: center;

        height: 100%;
        aspect-ratio: 1 / 1;
        width: auto;
    }

    .gallery-mobile-close img{
        border: none;
        display: block;

        height: 70%;
        width: auto;
    }

    .mobile-gallery-nav{
        display: block;
        position: absolute;
        z-index: 4000;
        width: 50vw;
        height: 93vh;

        top: 0px;
    }

    .mobile-gallery-nav-next{
        left: 50vw;
    }
    .mobile-gallery-nav-prev{
        left: 0px;
    }

    .gallery-img-portrait{
        width: 100%;
        height: auto;
    }
     
}