@keyframes new-image{
    0%{
        transform: translate(150%, 0px) rotate(20deg);
    }
    100%{
        transform: translate(0%, 0px) rotate(0deg);
    }
}

@keyframes new-text-mobile{
    0%{
        transform: translate(150%, 0px);
    }
    100%{
        transform: translate(0%, 0px);
    }
}


@keyframes new-aside-mobile{
    0%{
        transform: translate(-150%, 0px);
    }
    100%{
        transform: translate(0%, 0px);
    }
}

@keyframes new-text{
    0%{
        transform: translate(0%, 50%);
        opacity: 0.4;
    }
    100%{
        transform: translate(0%, 0%);
        opacity: 1;
    }
}

@keyframes new-aside{
    0%{
        opacity: 0.3;
    }
    100%{
        opacity: 1;
    }
}





#groupe-image-side{
    position: relative;
    width: 33vw;
    overflow: hidden;
}

#groupe-image-side img{
    width: 70%;
    animation: new-image 0.6s;
    animation-iteration-count: 1;
}


#groupe-main{
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 35vw;
    min-height: calc( 100vh - (0.2 * 100vw));
    height: 100%;

    overflow:hidden;

}

#groupe-text-content{
    display: block;

    height: 40rem;

    animation: new-text 0.6s;
    animation-iteration-count: 1;
    margin-bottom: 20px;
}

#groupe-text-content h2{
    width: fit-content;

    text-transform: uppercase;

    font-size: 7rem;
    color: var(--O1);

    font-family: 'Monoton';
    font-weight: 200;

    letter-spacing: 5px;

    margin: -10px 0px;

}

#groupe-text-content h3{
    display: flex;

    width: fit-content;

    padding: 10px 5px 5px 5px;
    margin-bottom: 10px;

    background-color: var(--B2);
    color: white;
    font-size: 2.5rem;
    font-weight: 200;
    font-family: 'Special Elite';
}

#groupe-text-content p{
    display: block;

    font-family: 'Arima Koshi Regular';
    font-size: 2.3rem;
    color: var(--B1);
    font-weight: 300;
}

#groupe-aside{

    color: white;

    font-family: 'Arima Koshi Regular';
    font-size: 1.6rem;

    height: fit-content;
    max-height: 66rem;

    overflow: auto;
    scrollbar-width: thin;

    width: 13vw;
    margin: 0px 2vw;
    padding: 5px 10px 10px 10px;

    background-color: var(--B2);

    animation: new-aside 0.6s;
    animation-iteration-count: 1;
}

#groupe-aside em{
    font-size: 1.8rem;
    display: block;
    margin-top: 10px;
    font-weight: 600;
}

.greyed-out{
    filter: contrast(0%);
}

.selected{
    filter: initial;
}

.greyed-out:hover{
    filter: initial;
}
#groupe-selector-outer{
    display: flex;
    justify-content: center;
    width: 100%;
}
.selector-default{
    cursor: alias;
    width: 17%;
}
#groupe-selector{
    width: 76%;

    margin-bottom: 25px;

    margin-right: -10%;
    
    position: relative;
}

#groupe-selector img{
    position: relative;
    transition: filter 0.4s ;
}
#groupe-selector img:hover{
    cursor: pointer;
}

#xavier{
    width: 25.5%;
    z-index: 3;
}
#antoine{
    width: 14%;
    z-index: 4;
    margin-left: -5%;
}
#manu{
    width: 16%;
    z-index: 6;
    margin-left: -3%;
}
#seb{
    width: 14%;
    z-index: 5;
    margin-left: -2.2%;
}
#marion{
    width: 13%;
    z-index: 4;
    margin-left: -2.8%;
}
#fred{
    width: 18%;
    z-index: 3;
    margin-left: -3.5%;
}
@media screen and (max-width: 1250px) {
    #groupe-main{
        width: 38vw;
    }
    #groupe-aside{
        width: 16vw;
    }
    #groupe-image-side{
        width: 25vw;
    }
    #groupe-image-side img{
        width: 90%;
    }
}

/*****************CSS MOBILE**********************/
@media screen and (max-width: 800px){
    #groupe-main{
        margin-top: 20px;
        width: 90vw;
        min-height: auto;
    }
    #groupe-selector{
        width: 100%;
    }
    .selector-default{
        display: none;
    }

    #groupe-text-content{
        height: fit-content;
        width: 100%;

        animation: new-text-mobile 0.6s;
        animation-iteration-count: 1;

        margin-bottom: 0px;
    }

    #groupe-text-content h2{
        font-size: 6.3rem;
    }

    #groupe-aside-mobile{
        color: white;

        font-family: 'Arima Koshi Regular';
        font-size: 1.8rem;

        height: fit-content;
        max-height: fit-content;
        
        width: 90vw;
        margin: 0px auto;
        margin-top: 20px;
        padding: 5px 10px 10px 10px;

        background-color: var(--B2);

        animation: new-aside-mobile 0.6s;
        animation-iteration-count: 1;

    }

    #groupe-aside-mobile em{
        font-size: 2rem;
        display: block;
        margin-top: 10px;
        font-weight: 600;
    }

}