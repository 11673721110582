.extrait-modal-button{
    display: inline-block;

    cursor: pointer;

    border: none;
    box-shadow: none;
    border-image: none;

    font-family: 'Special Elite', cursive;
    font-size: 2rem;
    line-height: 2.5rem;

    color: white;
    background-color: var(--O2);

    margin-left: 8px;
    padding: 3px 3px 0px 3px;
}

.extrait-modal-button:hover{
    background-color: var(--O1);
}


.modal-outer{
    z-index: 4000;

    position: fixed;
    top:0px;
    left: 0px;

    width: 100vw;
    height: 100vh;

    background-color: var(--B1Transp);

    justify-content: center;
    align-items: center;
}

.modal-inner{
    background-color: white;
    border-radius: 25px;

    padding: 15px;


    min-width: 450px;
    width: fit-content;
    height: fit-content;

    overflow: hidden;
}

.simple-video-player{
    height: auto;
    width: 45vw;

    aspect-ratio: 560 / 340;

    margin-bottom: -0.4vw;
}

@media screen and (max-width: 800px){
    .modal-inner{
        min-width: 0;
        width: 100vw;
    }
    .simple-video-player{
        width: 100%;
        margin-bottom: -2.5vw;
    }
}