@keyframes fade-in {
    0%{
        opacity: 0.6;
    }100%{
        opacity: 1;
    }
}

@keyframes fade-in-mobile {
    0%{
        color: rgb(255, 255, 255, 0.4);
    }100%{
        color: rgb(255, 255, 255, 1);
    }
}


@keyframes slide-ltr {
    0%{
        transform: translateX(-100%);
    }100%{
        transform: translateY(0%);
    }
}

@keyframes slide-rtl {
    0%{
        transform: translateX(100%);
    }100%{
        transform: translateY(0%);
    }
}

#video-gallery-cont{
    width: 48vw;
    margin-right: 5vw;
    margin-left: 2vw;
}

.video-item{
    width: 100%;
    overflow-x: hidden;
}

.video-item-title{
    
    background-color: var(--B1);

    z-index: 100;

    width: fit-content;
    padding: 10px 10px 5px 10px;

    animation: fade-in 0.6s ease-out 1, slide-ltr 0.6s ease-out 1;
}
.video-item-title h2{
    font-family: 'Special Elite', serif;
    font-size: 3rem;
    font-weight: 300;
    color: white;
}
.video-gallery-player-cont{
    width: 100%;
    height: fit-content;
    z-index: 1000;
    animation: fade-in 0.6s ease-out 1, slide-rtl 0.6s ease-out 1;

    border-radius: 0px 25px 25px 25px;
    overflow: hidden;
    background-color: #282828;
}
.video-gallery-player{
    width: 100%;
    height: auto;
    aspect-ratio: 560 / 340;

    margin-bottom: -0.15vw;
}

.extrait-gallery-custom{
    height: calc(48vw / 1.64);
}

@media screen and (max-width: 800px) {
    #video-gallery-cont{
        width: 100vw;
        margin: 0px;
    }
    .video-item-title{
        display: block;
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        height: fit-content;
        animation: none;

        background-color: var(--B3);

        padding-bottom: 8px;
    }
    .video-item-title-cont{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .video-item-title h2{
        text-align: center;
        font-size: 2.5rem;
        margin-top: 3px;
        flex-grow: 1;
        animation: fade-in-mobile 0.6s ease-out 1;
    }
    .video-gallery-player-cont{
        border-radius: 0px;
    }

    .video-gallery-player{
        width: 100%;
    }
    .extrait-gallery-custom{
        height: fit-content;
    }
}