
header{
    position: relative;
}

#nav-desktop{
    position: relative;
    left: 23%;
    top:0px;
    height: fit-content;
    width: fit-content;

    margin-bottom: calc(-0.015 * 100vw);

    display: flex;
    column-gap: 20px;
}
#social-media-desktop{
    display: flex;
    column-gap: 15px;

    position: absolute;
    top: 0px;
    right: 20px;
    width: fit-content;
    height: 41px;
    margin-bottom: calc(-0.015 * 100vw);
}
#social-media-desktop path,
#social-media-desktop circle{
   fill: var(--B4); 
}
#social-media-desktop a:hover path,
#social-media-desktop a:hover circle{
   fill: var(--B1); 
}


#nav-mobile{
    display: none;
}

#banner-desktop{
    width: 100%;
    height: calc(0.13 * 100vw);
    background-image: url('https://media.vancouver-live.fr/site/charte/Header.jpg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

nav a{
    display: flex;
    align-items: flex-end;

    box-sizing: content-box;

    text-decoration: none;
    color: var(--B1);

    text-transform: uppercase;

    font-family: 'Special Elite', cursive;
    font-size: 2rem;

    width: fit-content;
    height: 35px;

    border-top: 6px solid rgb(0, 0, 0, 0);
}

nav a:hover{
    color: var(--O1);
}

a.nav-focus{
    border-top: 6px solid var(--O1);
    color: var(--O1);
}

#mobile-menu-nav a{
    border-top: none;
}

#mobile-menu-nav a.nav-focus{
    border-top: none !important;
}


/***********NAV MOBILE*************/
@media screen and (max-width: 800px) {
    #nav-desktop{
        display: none;
    }
    #banner-desktop{
        height: calc(0.34 * 100vw);
        background-image: url('https://media.vancouver-live.fr/site/mobile/Header_mobile.jpg');
    }

    #nav-mobile{
        position: relative;
        display: block;
        width: 100%;
        max-height: 100px;
        height: calc(0.2 * 100vw);

        overflow: hidden;
    }

    #nav-mobile h2{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-height: 100px;

        height: calc(0.2 * 100vw);
        font-family: 'Monoton', cursive;
        font-size: 40px;
        color: var(--B3);
        text-transform: uppercase;
        
    }

    #mobile-menu{
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        background-color: white;
        width: 100%;
        height: 100%;
        min-width: 0;

        transform: translateX(90%);
    }

    #mobile-menu-open{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 10%;

        color: white;
        font-family: 'Barlow Condensed', cursive;

        user-select: none;
        cursor: pointer;
        
    }

    .mobile-default{
        background-color: var(--B3);
    }
    .mobile-open{
        background-color: var(--O1);
        width: 5% !important;
        min-width: auto !important;
    }

    #mobile-menu-open h5{
        font-size: 22px;
        letter-spacing: 2px;

        pointer-events: none;
    }

    #mobile-menu-nav{
        background-color: var(--O7);

        

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;

        /*min-width: 80%;*/
        flex: 1;

        height: 100%;

        padding: 5px 0px 5px 30px;

        column-gap: 10px;
        row-gap: 0px;
    }
    #mobile-menu-nav a{
        display: flex;
        width: fit-content;
        max-height: 40px;
        height: calc((0.1 * 100vw) - 10px);
        font-size: 20px;
    }

    #mobile-menu-close{
        max-width: fit-content;
        width: 12%;
        height: 100%;
    }
    #mobile-menu-close img{
        height: 100%;
        width: auto;
    }
}