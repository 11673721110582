.audio-player{
    position: relative;
    display: flex;
    width: 100%;
    height: 35px;
    justify-content: space-around;
    align-items: center;

    background-color: rgb(255, 255, 255, 0.5);

    font-size: 1.8rem;

    border-radius: 3px;

    overflow: hidden;
}

.audio-player button{
    appearance: none;
    background-color: transparent;
    border: none;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 35px;
    width: 30px;

    color: var(--B3);

    font-size: 2rem;
}

.audio-player button img{
    display: block;
    height: 50%;
    width: auto;
}

.audio-progress-cont{
    display: flex;
    align-items: center;
    position: relative;
    width: 80%;
    height: 100%;
}

.audio-progress{
    position: relative;
    height: 4px;
    width: 100%;

    background-color: var(--B6);
    flex-grow: 0;
}

.audio-progress-indicator{
    position: absolute;
    top: -4px;
    width: 8px;
    height: 12px;

    background-color: var(--B2);

    border-radius: 3px;

    transition: left 0.8s;
}

.audio-runtime{
    user-select: none;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 55px;
    
    font-family: 'Arima Koshi Regular', serif;
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0px 5px 0px 10px;

    margin-top: 3px;
}

.runtime-stopped{
    color: var(--B3);
}
.runtime-playing{
    color: var(--O1);
}