.photo-gallery-mobile{
    display: none;
}

.photo-gallery-desktop{
    all: unset;

    cursor: pointer;
    position: absolute;
    height: 5vw;
    width: auto;


    right: 0px;
    top: calc((48vw / 1.64) + (3rem + 15px) - 5vw);
    margin-right: 25px;
}

.photo-gallery-desktop:hover #photo-gallery-button,
.gallery-opened #photo-gallery-button{
    opacity: 0;
}
.photo-gallery-desktop:hover #gallery-photo-button-hover,
.gallery-opened #gallery-photo-button-hover{
    opacity: 1;
}

#photo-gallery-button{
    height: 100%;
    width: auto;
    margin-right: 0px;
    opacity: 1;
    font-family: 'Special Elite';
}
#gallery-photo-button-hover{
    position: absolute;
    opacity: 0;
    height: 100%;
    width: auto;
    margin-right:0px;
    top: 0px;
    left: 0px;
}

@media screen and (max-width: 1450px) {
    .photo-gallery-desktop{
        height: 7vw;
        top: calc((48vw / 1.64) + (3rem + 15px) - 7vw);
    }
}

@media screen and (max-width: 800px){
    .photo-gallery-mobile{
        all: unset;

        width: 100%;
        display: block;

        width: fit-content;
        height: fit-content;

        margin: 20px auto -10px auto;
    }

    .photo-gallery-mobile img{
        width: 50vw;
        height: auto;
    }
}