
@import url('https://fonts.googleapis.com/css2?family=Monoton&family=Special+Elite&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@800&display=swap');

@font-face {
    font-family: 'Arima Koshi Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Arima Koshi Regular'), url('fonts/ArimaKoshi-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Arima Koshi Black';
    font-style: normal;
    font-weight: normal;
    src: local('Arima Koshi Black'), url('fonts/ArimaKoshi-ExtraBold.woff') format('woff');
}

:root{
    --B1: #384d61;
    --B2: #476e94;
    --B3: #5c80a3;
    --B4: #85a6c7;
    --B5: #80cfe0;
    --B6: #85a7c7;

    --O1: #e04714;
    --O2: #eb694d;
    --O3: #ffb58a;
    --O4: #f5b0a1;
    --O5: #b56961;
    --O6: #ffebb5;
    --O7: #f5f2e4;

    --B5GradientTop: rgb(128, 207, 224);
    --B5GradientBot: rgb(128, 207, 224, 0.6);

    --O3GradientTop: rgb(255, 181, 138);
    --O3GradientBot: rgb(255, 181, 138, 0.6);

    --B1Transp: rgb(56, 77, 97, 0.9);

}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
#root{
    width: 100%;
    min-height: 100vh;
}

body{
    position: relative;
    min-height: 100vh;
    height: fit-content;
    z-index: 0;

    background-image: url('https://media.vancouver-live.fr/site/charte/Motif_gauche.jpg'), url('https://media.vancouver-live.fr/site/charte/Motif_droit.jpg');
    background-position: left bottom, right bottom;
    background-repeat: no-repeat, no-repeat;
    background-size: 33%, 33%;

    overflow: auto;
}

main{
    position: relative;
    padding-top: 2%;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    min-height: calc(100vh - (0.134 * 100vw));
    height: fit-content;
}

.mobile-only-block, 
.mobile-only-flex{
    display: none;
}

/* TEXTE VERTICAL */
.rotated {
    transform: rotate(-90deg);
}

.titre-page-aside{
    position: relative;
    display: flex;
    width: 15vw;
    justify-content: flex-end;
    align-items: flex-start;
}

.titre-page-aside img{
    /*width: 2.5vw;*/
    height: calc(0.28 * 100vw);
    width: auto !important;
    margin-right: 25px;
}

@media (-webkit-device-pixel-ratio: 2){
    html{
        font-size: 9px;
    }
}

@media (-webkit-device-pixel-ratio: 1.5){
    html{
        font-size: 8px;
    }
}

/***********    FONT POUR ECRAN AVEC ZOOM DE 125%   ***************/
@media (-webkit-device-pixel-ratio: 1.25){
    html{
        font-size: 8px;
    }
}

/*****        FONT POUR ECRAN SANS ZOOM       *******/
@media (-webkit-device-pixel-ratio: 1){
    html{
        font-size: 9px;
    }
}
@media screen and (max-width: 1450px) {
    html{
        font-size: 8px;
    }
}

/*************** STYLE MOBILE ***************/
@media screen and (max-width: 800px){
    html{
        touch-action: manipulation;
        font-size: 7px;
    }

    main{
        height: fit-content;
        min-height: 0;

        flex-direction: column;
        align-items: center;
        padding-top: 0px;
    }

    body{
        height: auto;

        min-height: auto;

        /*background-image: none;*/
    }

    .mobile-only-block{
        display: block;
    }

    .mobile-only-flex{
        display: flex;
    }

    .desktop-only{
        display: none !important;
    }

}
