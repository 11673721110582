#setlist{
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    width: 33vw;
    min-height: 64vh;
    height: fit-content;
    margin-bottom: 60px;
}
#setlist-sort{
    width: 100%;
    display: flex;
    flex-direction: column;
}

#setlist-sort-tab{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.setlist-sort-tab{
    display: flex;
    align-items: center;

    padding: 5px 8px 0px 8px;
    width: fit-content;
    height: fit-content;
    font-family: 'Special Elite', cursive;
    font-size: 3rem;
    color: white;

    user-select: none;
    cursor: pointer;
}

.setlist-sort-annee{
    background-color: var(--O1);
}

.setlist-sort-ABC{
    background-color: var(--B2);
}

.not-focused{
    opacity: 0.4;;
}

.setlist-separator{
    display: block;
    height: 8px;
    width: 100%;
}

.setlist-separator-annee #separator1{
    background-color: var(--O1);
}
.setlist-separator-annee #separator2{
    background-color: var(--O4);
}
.setlist-separator-annee #separator3{
    background-color: var(--O6);
}
.setlist-separator-ABC #separator1{
    background-color: var(--B2);
}
.setlist-separator-ABC #separator2{
    background-color: var(--B3);
}
.setlist-separator-ABC #separator3{
    background-color: var(--B4);
}

#setlist-nav{
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    flex-wrap: wrap;

    font-family: 'Monoton', cursive;
    font-size: 4.6rem;
    user-select: none;
}

.setlist-nav-top{
    display: flex;
    width: 100%;
    padding: 0px 12%;

    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.nav-annee{
    color: var(--O4);
}

.nav-ABC{
    color: var(--B4);
}


.setlist-nav-button{
    background-color: white;
    box-shadow: none;
    border: none;
    display: block;
    width: 50px;
    height: 50px;

    cursor: pointer;
}

.nav-annee .setlist-nav-button:hover svg circle{
    fill: var(--O1) !important;
}

.nav-ABC .setlist-nav-button:hover svg circle{
    fill: var(--B2) !important;
}

.setlist-nav-separator{
    display: block;
    width: 100%;
    height: 3px;
}

.nav-annee .setlist-nav-separator{
    background-color: var(--O6);
}

.nav-ABC .setlist-nav-separator{
    background-color: var(--B4);
}

.setlist-group{
    display: inline-block;
    column-span: none;
    column-count: 1;
    break-inside: avoid;
    width: 100%;
    margin-top: 10px;
    font-family: 'Arima Koshi Regular', serif;
    color: var(--B1);
    font-size: 2rem;
}

.setlist-artiste-name{
    display: inline-block;
    width: 100%;
    font-family: 'Arima Koshi Black', serif;
    font-size: 2.4rem;
}

.setlist-track{
    display: inline-block;
    width: 100%;

}

#setlist-cont{
    display: block;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: clip;
    -ms-overflow-style: thin;  /* IE and Edge */
    scrollbar-width: thin;  /* Firefox */
}
#setlist-window{
    height: fit-content;
    break-inside: avoid;
    display: block;
    columns: 2;
    width: 100%;
}




#setlist-cont::-webkit-scrollbar{
    display: none;
}

@media screen and (max-width: 1250px) {
    #setlist{
        width: 38vw;
    }
}

@media screen and (max-width: 800px) {
    .setlist-sort-tab{
        height: 9rem;
        font-size: 3rem;
        width: 50%;
        justify-content: center;
    }

    .setlist-nav-top{
        padding:3px 2%;
    }
    
    #setlist{
        display: flex;
        flex-direction: column;
        font-size: 2rem;
        width: 100%;
        min-height: auto;
        margin-bottom: 00px;
    }

    #setlist-cont{
        max-height: fit-content;
        padding:0px 4%;
    
        overflow: auto;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    #setlist-window{
        height: fit-content;
        break-inside: avoid;
        display: block;
        columns: 1;
        width: 100%;
    }

    .setlist-group{
        font-size: 2.4rem;
    }
    
    .setlist-artiste-name{
        font-size: 2.8rem;
    }
}