@keyframes fleche-apres {
    0%{
        transform: translateX(0%);
    }100%{
        transform: translateX(30%);
    }
}
@keyframes fleche-avant {
    0%{
        transform: translateX(0%);
    }100%{
        transform: translateX(-30%);
    }
}

.nav-buttons{
    display: flex;
    width: 100%;
    justify-content: center;

    column-gap: 30px;

    margin-top: 20px;
    margin-bottom: 20px;
}
.nav-mobile{
    display: none;
}

.nav-buttons button{
    all: unset;
    cursor: pointer;

    display: block;

    width: 40px;
    height: 40px;
}
.nav-buttons-next:hover,
.nav-buttons-prev:hover{
    animation-duration: 0.4s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-out;
}

.nav-buttons-next:hover{
    animation-name: fleche-apres;  
}
.nav-buttons-prev:hover{
    animation-name: fleche-avant;
}

.nav-buttons-next .fleche-apres circle,
.nav-buttons-prev .fleche-avant circle{
    transition: fill 0.4s;
}
.nav-buttons-next:hover .fleche-apres circle,
.nav-buttons-prev:hover .fleche-avant circle{
    fill: var(--B3) !important;
}

@media screen and (max-width: 800px) {
    .nav-buttons{
        display: none;
    }

    .nav-mobile{
        all: unset;

        display: block;
        height: auto;
        width: 10%;
    }
    
}