@keyframes fadein-up {
    0%{
        opacity: 0;
        transform: translateY(15%);
    }100%{
        opacity: 1;
        transform: translateY(0%);
    }
}


#zoom{
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 23vw;
    height: fit-content;

    background-color: var(--O6);
    border: solid 2px var(--O4);
    border-radius: 30px;

    padding: 25px;

    margin-left: 7vw;
    

    overflow: hidden;

    animation-name: fadein-up;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
}

#zoom-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    margin-bottom: 12px;
}

#zoom-logo{
    display: block;
    width: 13%;
    align-self: flex-start;
}

#zoom-cover{
    width: 82%;
    height: auto;
}

#zoom-body h3{
    display: block;
    width: fit-content;
    font-family: 'Special Elite';
    font-size: 2.6rem;

    font-weight: 400;

    padding: 4px 12px 0px 12px;
}

#zoom-texte{
    margin-top: 12px;
    font-family: 'Special Elite';
    font-size: 1.9rem;
    line-height: 2.4rem;
    color: var(--B3);
}

#zoom-artiste{
    background-color: var(--B2);
    color: white;
}

#zoom-titre{
    background-color: white;
    color: var(--B1);
}

#zoom-annee{
    color: var(--B4);
}
@media screen and (max-width: 1250px) {
    #zoom{
        width: 26vw;
    }
}

@media screen and (max-width: 800px) {
    #zoom{
        margin: 0px;
        margin-top: 30px;
        margin-bottom: 20px;
        width: 92%;

        animation: none;
    }

    #zoom-body h3{
        font-size: 3rem;
    }

    #zoom-texte{
        font-size: 2.3rem;
    }
}