
@keyframes new-extract {
    0%{
        transform: translateY(25%);
        opacity: 0.4;
    }
    100%{
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes fadein-up {
    0%{
        opacity: 0;
        transform: translateY(15%);
    }100%{
        opacity: 1;
        transform: translateY(0%);
    }
}

.extrait-outer-cont{
   height: fit-content;
   overflow: hidden;
}


.extrait-cont{

    display: flex;
    flex-direction: column;

    padding: 20px 30px;
    width: 23vw;
    height: 27.3vw;
    margin-top: calc(3rem + 15px);

    border-radius: 25px;

    overflow: hidden;

    background-image: linear-gradient(var(--B5GradientTop), var(--B5GradientBot));

    animation-name: fadein-up;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
}
.extrait-cont h3{
    display: block;
    width: fit-content;
    height: fit-content;

    font-family: 'Special Elite', serif;
    font-size: 2.5rem;
    font-weight: 400;

    padding: 5px 7px 0px 7px;
}

.extrait-content{
    animation-duration: 0.6s;
    animation-name: new-extract;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
}

#extrait-k7{
    display: flex;
    flex: 1;
    justify-content: center;
    min-width: 0;
    min-height: 0;
    max-width: 100%;
    max-height: 55%;

    margin: auto;
}

#extrait-k7 img{
    height: 100%;
    width: auto;
}

.extrait-header{
    width: 100%;
}

.extrait-header h2{
    text-transform: uppercase;

    text-align: center;
    font-family: 'Monoton', monospace;
    font-weight: 100;
    font-size: 6rem;
    letter-spacing: 3px;

    color: white;
    height: fit-content;
}

.extrait-artiste{
    background-color: var(--B3);
    color: white;

    margin-top: 20px;
}

.extrait-titre{
    background-color: white;
    color: var(--B1);
}
.extrait-annee{
    background-color: white;
    color: var(--B3);
}

@media screen and (max-width: 1450px) {
    .extrait-header h2{
        font-size: 4.5rem;
    }
}

@media screen and (max-width: 800px){
    .extrait-outer-cont{
        margin-top: 30px;
        position: relative;
        width: 90%;
    }
    .extrait-cont{
        animation: none;
        position: relative;
        width: 100%;
        height: fit-content;
        margin: auto;
        margin-top: 0px;
        padding-bottom: 23px;
    }

    .extrait-header{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
    }

    .extrait-header h2{
        width: 100%;
        text-align: center;
        font-size: 5.5rem;
        letter-spacing: 3px;
    }

    #extrait-k7{
        display: none;
    }

}