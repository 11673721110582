#setlist-vinyl{
  position: relative;

  display: block;
  width: 17vw;  
  height: calc(17vw * 1.26);

  margin-right: 13vw;

  z-index: 3;
}

#setlist-vinyl-cover{
    display: block;

    position: relative;

    width: 100%;
    height: 100%;

    background-image: url("https://media.vancouver-live.fr/site/setlist/Setlist_pochette.png");

    background-size: auto 100%;

    background-repeat: no-repeat;

    z-index: 10;
}



#setlist-vinyl-disc{
    display: block;

    position: absolute;

    right: -7.5vw;
    top: 1%;
    width: calc(95% * 1.26);
    height: 95%;

    background-image: url("https://media.vancouver-live.fr/site/setlist/Setlist_vinyl.png");

    background-size: auto 100%;

    background-repeat: no-repeat;

    z-index: 5;

}

@keyframes in-out{
    0% {transform: translateX(0%);}
    50% {transform: translateX(-17%);}
    100% {transform: translateX(0%);}
}

@keyframes spin{
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

.play-in-out{
    animation-name: in-out;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
}

.play-spin{
    animation-name: spin;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
}

@media screen and (max-width: 1250px) {
    #setlist-vinyl{
        width: 14vw;  
        height: calc(14vw * 1.26);
      
        margin-right: 10vw;
      }
      #setlist-vinyl-disc{  
          right: -4.5vw;
      }
}

@media screen and (max-width: 800px) {
    #setlist-vinyl{
        display: none;
    }  
}
