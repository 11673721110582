#mobile-footer{
    display: none;

    margin-top: 20px;
    margin-bottom: 20px;

    padding: 0px 5%;
}
#linkaband-mobile{
    flex-grow: 1;
    height: fit-content;
    width: 100%;
    height: 70px;
}

#linkaband-mobile img{
    display: block;
    height: 100%;
    width: auto;
}

#social-media-mobile{
    display: flex;
    column-gap: 15px;
    width: fit-content;
    height: 40px;
    color: var(--B3);
}
#social-media-mobile a{
    display: block;

    height: 40px;
    width: 40px;
}

#social-media-mobile svg{
    width: 100%;
    height: 100%;
}

#social-media-mobile path,
#social-media-mobile circle{
   fill: var(--B4); 
}
#social-media-mobile a:hover path,
#social-media-mobile a:hover circle{
   fill: var(--B1); 
}

@media screen and (max-width: 800px) {
    #mobile-footer{
        display: flex;
        width: 100vw;
        height: fit-content;

        align-items: flex-end;

        overflow: hidden;
    }
}